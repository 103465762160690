$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltip();
  M.AutoInit();
});

//===================================================
// Global functions

function displayMessage(message, isError) {

  if (typeof isError !== 'undefined' && isError === true) {
    displayError(message);
  } else {
    toastr.success(message, undefined, {
      "timeOut": 10000,
    });
  }
}

function displayError(error) {
  toastr.error(error, undefined, {
      "closeButton": true,
      "timeOut": 0,
      "extendedTimeOut": 0
    }
  );
}
